export const advocacy = require("./wwd_advocacy.png");
export const background = require("./home-header.jpg");
export const backgroundBlur = require("./home-header-blur.jpg");
export const capacity = require("./wwd_capacity.png");
export const coordination = require("./wwd_coordination.png");
export const cycle = require("./cycle.jpg");
export const cycleBlur = require("./cycle-blur.jpg");
export const funding = require("./wwd_funding.png");
export const reframe = require("./reframe.jpg");
export const reframeBlur = require("./reframe-blur.jpg");
